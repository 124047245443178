import React from "react"
import {HandySvg} from "handy-svg"
import {Link} from "react-router-dom"

import file_doc from "../../../ico/file_doc.svg"
import file_pdf from "../../../ico/file_pdf.svg"
import {BACK_HOST} from "../../../Consts";

function ListBlockItemFile({title, link, type, path, size, date}) {

	function getIcoByFile(file) {
		switch (file) {
			case 'doc':
				return (<HandySvg src={file_doc} width={20} height={18}/>)
			case 'voyant':
				return <svg width="20" height="20" viewBox="0 0 24 24">
					<path d="M12,16C12.56,16.84 13.31,17.53 14.2,18L12,20.2L9.8,18C10.69,17.53 11.45,16.84 12,16M17,11.2A2,2 0 0,0 15,13.2A2,2 0 0,0 17,15.2A2,2 0 0,0 19,13.2C19,12.09 18.1,11.2 17,11.2M7,11.2A2,2 0 0,0 5,13.2A2,2 0 0,0 7,15.2A2,2 0 0,0 9,13.2C9,12.09 8.1,11.2 7,11.2M17,8.7A4,4 0 0,1 21,12.7A4,4 0 0,1 17,16.7A4,4 0 0,1 13,12.7A4,4 0 0,1 17,8.7M7,8.7A4,4 0 0,1 11,12.7A4,4 0 0,1 7,16.7A4,4 0 0,1 3,12.7A4,4 0 0,1 7,8.7M2.24,1C4,4.7 2.73,7.46 1.55,10.2C1.19,11 1,11.83 1,12.7A6,6 0 0,0 7,18.7C7.21,18.69 7.42,18.68 7.63,18.65L10.59,21.61L12,23L13.41,21.61L16.37,18.65C16.58,18.68 16.79,18.69 17,18.7A6,6 0 0,0 23,12.7C23,11.83 22.81,11 22.45,10.2C21.27,7.46 20,4.7 21.76,1C19.12,3.06 15.36,4.69 12,4.7C8.64,4.69 4.88,3.06 2.24,1Z"></path>
				</svg>
			default:
				return (<HandySvg src={file_pdf} width={20} height={18}/>)
		}
	}

	const dateString = new Date(date).toLocaleDateString('ru') + "\n" + new Date(date).toLocaleTimeString('ru')

	let fileExt = path.match(/\.[0-9a-z]+$/i)
	if(fileExt)
		fileExt = fileExt[0]

	return <div className="list-block-itm">
		<div className="list-block-itm__info">
			{link
				? <Link to={link} className="list-block-itm__info__title" >{title}</Link>
				: <div className="list-block-itm__info__title">{title}</div>
			}

			<div  className="list-block-itm__info__file">
				{type === "voyant"
					? <Link className="list-block-itm-file-link" to={"/stream/voyant?file="+`${BACK_HOST}/${path}`} >
						{getIcoByFile(type)}
						<span>{fileExt}</span>
					</Link>
					: <a href={`${BACK_HOST}/${path}`} download={true} className="list-block-itm-file-link">
						{getIcoByFile(type)}
						<span>{fileExt}</span>
					</a>
				}
				<span className="sep"></span>
				<span>{size}</span>
			</div>
		</div>
		<div className="list-block-itm__date">{dateString}</div>
	</div>
}

export default ListBlockItemFile
import {useDispatch, useSelector} from "react-redux";
import Actions from "./Actions";

export default function useAsyncDispatcher({action, state}) {
	const arrState = state.split('.')
	const store = useSelector((state) => state[arrState[0]])
	const actions = Actions()

	if (action && state){
		if (!store[arrState[1]] ||
			(Array.isArray(store[arrState[1]]) && store[arrState[1]].length === 0) ||
			(typeof store[arrState[1]] === 'object' && Object.keys(store[arrState[1]]).length === 0)
		){
			if(!actions[action]) {
				console.error('useAsyncDispatcher Fail: ', 'Action not found')
				return
			}
			actions[action]()
		}
	}else{
		console.error('useAsyncDispatcher Fail: ', (!action)? 'Action is not set' : '', (!state)? 'State is not set' : '')
	}
}
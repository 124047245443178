import React, {useEffect, useState} from "react"
import {analyticsBACK} from "../../ApiEndpoints";
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";

import '../../styles/pages/admin-cabinet.sass'
import EditButton from "../../components/ui/buttons/EditButton";
import Modal from "../../components/ui/Modal";
import FormErrors from "../../components/ui/FormErrors";
import {HandySvg} from "handy-svg";
import svg_arrow from "../../ico/arrow.svg";
import PrettySelect from "../../components/ui/PrettySelect";
import SmallButton from "../../components/ui/buttons/SmallButton";

const InputField = ({children}) => {
    return <div className="col">
        <label className="input-wrap">
            {children}
        </label>
    </div>
}

export default function AdminCabinet(){
    const currentUser = useSelector(state => state.user.value)
    const [users, setUsers] = useState([])
    const [userToChange, setUserToChange] = useState({})
    const [groups, setGroups] = useState([])
    const [busy, setBusy] = useState(false)
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [query, setQuery] = useSearchParams()
    const [modalOpened, setModalOpened] = useState(false)
    const [formBusy, setFormBusy] = useState(false)
    const [errors, setErrors] = useState([])
    const fields = [
        {name: 'firstName', required: true, label: 'Имя'},
        {name: 'lastName', required: true, label: 'Фамилия'},
        {name: 'job', required: true, label: 'Место работы/учебы'},
        {name: 'post', required: true, label: 'Должность'},
        {name: 'group', label: 'Группа', type: 'select'}
    ]
    const [siteSettings, setSiteSettings] = useState({})
    const [settingsChange, setSettingsChange] = useState(false)
    const [settingsChangeError, setSettingsChangeError] = useState([])

    const makeForm = () => {
        return <div className="cols cols-2 p10" >
            <input type="hidden" value={userToChange.id} name="userID"/>
            <input type="hidden" value={currentUser.id} name="author"/>
            {fields.map((input) => {
                if(input.type === 'empty')
                    return <div className="col"></div>

                if(input.type === "select"){
                    const options = groups.map((group) => {
                        return {value: group.id, text: group.ru, selected: (userToChange[input.name] === group.id)}
                    })
                    return <InputField key={input.name}>
                        <select key={'select_'+input.name}
                            required={true}
                            name={input.name}
                            defaultValue={userToChange[input.name]}>
                            {
                                options.map((option) => {
                                    if(option.value === 1)
                                        return ''
                                    return <option key={option.text} value={((option.value)?? option.text)}>{option.text}</option>
                                })
                            }
                        </select>
                    </InputField>
                }

                return <InputField>
                    <input
                        defaultValue={userToChange[input.name]}
                        name={input.name}
                        type={(input.type)?? 'text'}
                        placeholder=" "
                        required={(input.required)?? 'false'}
                    />
                    <span className="input-wrap__label">{input.label}</span>
                </InputField>
            })}
        </div>
    }

    const getUsersPage = (reqPage = 1) => {
        analyticsBACK.get('/user-list', {
            params: {page:reqPage}
        })
            .then((resp) => {
                setUsers(resp.data.users)
                setTotal(resp.data.total)
                setPage(reqPage)
            })
            .catch((resp) => {
                console.error('USER_LIST_FETCH_FAIL')
            })
            .finally(() => {setBusy(false)})
    }

    useEffect(() => {
        getUsersPage(query.get('page') ?? 1)

        analyticsBACK.get('/getUserGroups')
            .then((resp) => {
                setGroups(resp.data.groups_ids)
            })
        analyticsBACK.get('/settings')
            .then((resp) => {
                setSiteSettings(resp.data)
            })
            .catch((resp) => {
                console.error('FAIL_SETTINGS_FETCH')
            })
    }, []);

    useEffect(() => {
        if(page !== 1)
            setQuery({page})
        else
            setQuery({})
    }, [page])

    const removeUser = (userID) => {
        setBusy(true)
        analyticsBACK.delete('/local-user', {
            data: {
                userID: userID
            }
        })
            .then((resp) => {
                setUsers(resp.data.users)
                setTotal(resp.data.total)
            })
            .catch((resp) => {
                console.error('USER_DELETE_FAIL')
            })
            .finally(() => {setBusy(false)})
    }

    const getChangeForm = (user) => {
        setUserToChange(user)
        setModalOpened(true)
    }

    const makeUsersTable = () => {
        return users.map((user) => {
            const dateCreate = new Date(user.created_at)
            const dateUpaded = new Date(user.updated_at)
            const group = groups.find((gr) => gr.id === user.group)
            return <>
                <div className="users-table__item users-table__item--head">{user.login}</div>
                <div  className="users-table__item">{user.firstName}</div>
                <div  className="users-table__item">{user.lastName}</div>
                <div  className="users-table__item">{user.job}</div>
                <div  className="users-table__item">{user.post}</div>
                <div  className="users-table__item">{(group && group.id)? group.ru : ''} ({(group && group.id)? group.id : user.group})</div>
                <div className='users-table__item date'>{dateCreate.toLocaleDateString('ru')}<br />{dateCreate.toLocaleTimeString('ru')}</div>
                <div className='users-table__item date'>
                    {dateUpaded.toLocaleDateString('ru')}<br />{dateUpaded.toLocaleTimeString('ru')}
                    {user.author && user.author.name &&
                        <><br/>({user.author.name})</>
                    }
                </div>
                <div className="users-table__item flex_row flex_row--gap_w_normal">
                    <EditButton clickResponse={() => {getChangeForm(user)}} />
                    <div className="cross" onClick={() => {removeUser(user.id)}}></div>
                </div>
            </>
        })
    }

    const handlePageChange = (newPage) => {
        getUsersPage(newPage)
    }

    const makePagination = () => {
        if(total <= 20)
            return ''

        const pagesHTMl = [];
        const pagesCount = Math.ceil(total / 20)
        for (let i = 1; i <= pagesCount; i++){
            if(page === i)
                pagesHTMl.push(<li className="active">{i}</li>)
            else
                pagesHTMl.push(<li onClick={() => {handlePageChange(i)}}>{i}</li>)
        }
        return <div className='flex_row flex_row--sp_center users_pager_wrapper '>
            <ul className='users_pager flex_row flex_row--gap_w_normal'>{pagesHTMl}</ul>
        </div>
    }

    const handleChange = (e) => {
        e.preventDefault()
        if (userToChange){
            setFormBusy(true)
            const formData = new FormData(e.target)
            analyticsBACK.post('/local-user', formData)
                .then((resp) => {
                    setUsers(users.map(user => {
                        return (resp.data.id === user.id)? resp.data : user
                    }))
                    setModalOpened(false)
                })
                .catch((resp) => {
                    console.error("USER_CHANGE_FAIL")
                    setErrors(['Ошибка при изменении пользователя'])
                })
                .finally(() => {
                    setFormBusy(false)
                })

        }
    }

    const handleChangeSiteSetting = (e) => {
        e.preventDefault()
        setSettingsChange(false)
        setSettingsChangeError([])
        const formData = new FormData(e.target)
        analyticsBACK.post('/settings', formData)
            .then((resp) => {
                setSettingsChange(true)
            })
            .catch((resp) => {
                console.error('SETTINGS_CHANGE_FAIL')
                setSettingsChangeError(['Ошибка при попытки изменения настроек. ' +
                  ((resp.response.data && resp.response.data.message)? resp.response.data.message : '')])
            })
    }

    if(currentUser.group.code !== 'admin'){//если пользователь не админ
        return <main className={'main-content'}>
            <div className="content visible">
                <h1 className="title-big main-margin c-main-dark">Административный кабинет</h1>
                <p><b>У вас нет прав для просмотра этой страницы</b></p>
            </div>
        </main>
    }
    return <main className={'main-content'}>
        <div className="content visible">
            <h1 className="title-big main-margin c-main-dark">Административный кабинет</h1>
            <br />
            {Object.keys(siteSettings).length > 0 &&
                <div>
                    {settingsChange &&
                        <p>Данные успешно обновлены</p>
                    }
                    {settingsChangeError.length > 0 &&
                        <FormErrors errors={settingsChangeError} />
                    }
                    <form action='' onSubmit={(e) => handleChangeSiteSetting(e)} className="nice-form mt__normal">
                        <div className="cols cols-2 p10">
                            <div className="col">
                                <label className="input-wrap">
                                    <input
                                        type="email"
                                        name={'feedback_email'}
                                        placeholder=" "
                                        defaultValue={(siteSettings.feedback_email)? siteSettings.feedback_email : ''}
                                        required={true}
                                    />
                                    <div className="input-wrap__label">
                                        Emeil администратора обратной связи
                                    </div>
                                </label>
                            </div>
                            <div className="col">
                                <label className="input-wrap">
                                    <input
                                        type="email"
                                        name={'registrator_emeil'}
                                        placeholder=" "
                                        defaultValue={(siteSettings.registrator_emeil) ? siteSettings.registrator_emeil : ''}
                                        required={true}
                                    />
                                    <div className="input-wrap__label">
                                        Emeil администратора регистрации
                                    </div>
                                </label>
                            </div>
                            <div className="col">
                                <SmallButton>Обновить</SmallButton>
                            </div>
                        </div>
                    </form>
                </div>
            }
            <br/>
            {total > 0 &&
                <>всего пользователей: {total} <br/><br/></>
            }
            {busy
                ? <div className=' flex_row flex_row--sp_center'>
                    <div className="mini-loader"></div>
                </div>
                : <>
                    <div className="users-table grid-table grid-table--min-columns">

                        <div className="users-table__item users-table__item--head">Логин</div>
                        <div className="users-table__item users-table__item--head">Имя</div>
                        <div className="users-table__item users-table__item--head">Фамилия</div>
                        <div className="users-table__item users-table__item--head">Место работы</div>
                        <div className="users-table__item users-table__item--head">Должность</div>
                        <div className="users-table__item users-table__item--head">Группа</div>
                        <div className="users-table__item users-table__item--head">Дата<br/>создания</div>
                        <div className="users-table__item users-table__item--head">Дата<br/> Изменения</div>
                        <div className="users-table__item users-table__item--head"> </div>

                        {makeUsersTable()}
                    </div>
                    {makePagination()}
                </>
            }
            <Modal opened={modalOpened} setOpened={setModalOpened} className="user_edit" >
                <form action="" aria-disabled={formBusy} className="nice-form js-auth-form" onSubmit={(e) => handleChange(e)}>
                    {userToChange !== {} && modalOpened &&
                        makeForm()
                    }
                    {errors.length > 0 &&
                        <div className="form-message"><FormErrors errors={errors} /></div>
                    }
                    <br/><br/>
                    <button type='submit' className="btn big">
                        Сохранить
                        <HandySvg src={svg_arrow} width={20} height={18}/>
                    </button>
                </form>
            </Modal>
        </div>
    </main>
}
import React, {useEffect, useState} from "react"
import {APIClient} from "../../ApiEndpoints"
import {STREAM_FACET, STREAM_NAMED_ENTITY_FACET} from "../../graphql/streamQueries"
import StreamFacetItem from "./StreamFacetItem"
import BookListItem from "../ui/BookList/BookListItem"
import BookListWrapp from "../ui/BookList/BookListWrapp"
import './StreamFacet.scss'
import Skeleton from "react-loading-skeleton";

const StreamFacet = ({filterSettings}) => {
    const [facet, setFacet] = useState(false)
    const [busy, setBusy] = useState(false)

    useEffect(() => {
        setBusy(true)

        async function getData (filterSettings)  {
            const resp = await Promise.all([
                APIClient.query({
                    query: STREAM_FACET,
                    variables: {filterSettings}
                }),
                APIClient.query({
                    query: STREAM_NAMED_ENTITY_FACET,
                    variables: {filterSettings}
                }),
            ])
                .catch(() => {
                    setBusy(false)
                    setFacet('fail')
                })

            setBusy(false)
            if(resp && resp.length > 0)
                setFacet(resp)
        }

        getData(filterSettings)
    }, []);

    const titleConcepts = <>Наиболее часто<br />встречающиеся концепты</>
    const titleEntities = <>Наиболее часто<br />встречающиеся сущности</>

    return <div >
        <BookListWrapp>
            <BookListItem title={titleConcepts}>
                <div className="facet">
                    {busy === true &&
                        <>
                            <Skeleton height={'43px'} />
                            <div className="delayed_message">Загрузка данных может идти долго</div>
                        </>
                    }
                    {facet === 'fail' &&
                        <div>Недостаточно данных для построения отчета</div>
                    }
                    {facet !== false && typeof facet === "object" && facet.length > 0 &&
                        facet[0].data.paginationStory.listConceptCountFacet.map((item) => {
                            return <StreamFacetItem key={item.value.id} item={item} />
                        })
                    }
                </div>
            </BookListItem>
            <BookListItem title={titleEntities}>
                <div className="facet">
                    {busy === true &&
                        <>
                            <Skeleton height={'43px'} />
                            <div className="delayed_message">Загрузка данных может идти долго</div>
                        </>
                    }
                    {facet === 'fail' &&
                        <div>Недостаточно данных для построения отчета</div>
                    }
                    {facet !== false && typeof facet === "object" && facet.length > 0 &&
                        facet[1].data.paginationStory.listNamedEntityCountFacet.map((item) => {
                            return <StreamFacetItem key={item.value} item={item} mode={'namedEntities'}/>
                        })
                    }
                </div>
            </BookListItem>
        </BookListWrapp>
    </div>
}

export default StreamFacet

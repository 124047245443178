import React from "react";
import ico_search from "../../ico/search.svg";
import {HandySvg} from "handy-svg";

const SearchInput = ({query, setQuery, placeholder, submit = '', disabled = false, respAction, style = ''}) => {

	return <div className={"nice-form smart-search-block"} aria-disabled={disabled}>
		<div className={"smart-search-input flex_row " + style}>
			<label className="input-wrap with-ico">
				<HandySvg src={ico_search} width={20} height={18} className="input-wrap__ico"/>

				<input type="text" placeholder=" "
				       value={query}
				       onInput={(e) => setQuery(e.target.value)}
				       onFocus={(e) => {
					       let block = e.currentTarget.closest('.smart-search-block');
					       block.classList.add('smart-search-block--list')
				       }}/>
				<div className="input-wrap__label">{placeholder}</div>
			</label>
			{submit &&
				<button onClick={() => {respAction()}} className={'btn small source-block__select-all'}>{submit}</button>
			}
		</div>
	</div>
}

export default SearchInput
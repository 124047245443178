import React, {useEffect, useState} from "react";
import Skeleton from "react-loading-skeleton";
import {useSelector} from "react-redux";
import {getPlatformStatus} from "../../requests/platforms";
import Actions from "../../Dispatcher/Actions";

const PlatformTableList = ({platforms, selectedPlatforms, setSelectedPlatforms, saved = false}) => {
	const locale = useSelector((state) => state.platform.locale)
	const actions = Actions()
	const [statuses, setStatuses] = useState({})

	useEffect(() => { //retrieve types
		if(Object.keys(locale).length === 0) {
			actions.getPlatformsLocales()
		}
	}, [])

	const getStatus = (id) => {
		if(id){
			getPlatformStatus(id)
				.then((resp) => {
					if (resp.data.paginationPeriodicJob && resp.data.paginationPeriodicJob.listPeriodicJob[0])
						setStatuses((prev) => {
							return {...prev, [id]: resp.data.paginationPeriodicJob.listPeriodicJob[0].status}
						})
					else {
						setStatuses((prev) => {
							return {...prev, [id]: 'Disabled'}
						})
					}
				})
				.catch(() => {
					setStatuses((prev) => {
						return {...prev, [id]: 'Disabled'}
					})
				})
		}
	}

	useEffect(() => {
		platforms.forEach((platform) => {
			getStatus(platform.id)
		})
	//eslint-disable-next-line
	}, []);

	const togglePlatform = (platform) => {
		if (selectedPlatforms[platform.id]) {
			let tmpObj = selectedPlatforms
			delete tmpObj[platform.id]
			setSelectedPlatforms({...tmpObj})
		} else {
			setSelectedPlatforms({...selectedPlatforms, [platform.id]: platform})
		}
	}

	const toggleAll = () => { //активируем все элементы на странице
		if(saved)
			return false

		const tmpToggled = {...selectedPlatforms}

		if(checkToggleAll()) {
			platforms.forEach((platform) => {
				delete tmpToggled[platform.id]
			})
			setSelectedPlatforms(tmpToggled)
		}else{
			platforms.forEach((platform) => {
				tmpToggled[platform.id] = platform
			})
			setSelectedPlatforms(tmpToggled)
		}
	}

	const checkToggleAll = () => { //проверяем, если все активны, то возвращаем true
		let untoggled = 0
		if(!saved)
			platforms.forEach((platform) => { if ( !selectedPlatforms[platform.id])  {untoggled++} } )
		else return false

		return untoggled === 0;
	}

	const makeHeaad = () => {
		return <div className="platforms-table-list__item platforms-table-list__head-item">
			<div>
				{!saved && Object.keys(platforms).length > 0 &&
					<label className="checkbox" title='все, на этой странице'>
						<input
							onChange={() => {
								toggleAll()
							}}
							checked={checkToggleAll()}
							type='checkbox'
							name={'toggle_all'}
							value={''}/>
						<span className="checkbox__check"/>
					</label>
				}
			</div>
			<div>Название</div>
			<div>URL</div>
			<div>Страна</div>
			<div>Тип</div>
			<div>Язык</div>
			<div>Начало сбора</div>
			<div>Статус</div>
		</div>
	}

	const makeList = () => {
		return platforms.map((platform) => {
			let stDate = '-'
			if (platform.period.startDate) {
				stDate = `${(platform.period.startDate.date.day.toString().length === 1) ? '0' + platform.period.startDate.date.day : platform.period.startDate.date.day}.${(platform.period.startDate.date.month.toString().length === 1) ? '0' + platform.period.startDate.date.month : platform.period.startDate.date.month}.${platform.period.startDate.date.year}`
			}
			const parsedName = platform.name.replace(/((http|https)+(:\/\/))/i, '').replace(/\.\w{2,4}\/?$/i, '').replace(/(www\.)/i, '')
			const platformFromTable = false

			return <div key={platform.id} className="platforms-table-list__item">
				{!saved
					? <div>
						<label className="checkbox">
							<input
								onChange={() => {
									togglePlatform(platform)
								}}
								checked={!!(selectedPlatforms[platform.id])}
								type='checkbox'
								name={'SELECTED_PLATFORM['+platform.id+']'}
								value={platform.id}/>
							<span className="checkbox__check"/>
						</label>
					</div>
					: <div></div>
				}
				<div
					className={"line-fade text--capitalize"}>
					{platformFromTable
						? <>
							<p><b>{platformFromTable.NAME}</b></p>
							{platformFromTable.ORIGIN &&
								<p>{platformFromTable.ORIGIN}</p>
							}
						</>
						: <b>{(parsedName) ? parsedName : ''}</b>
					}
				</div>
				<div className={"line-fade"}>
					{(platform.url) ? <a href={platform.url}
					                     target={'_blank'} rel={'noreferrer'}>{platform.url.replace(/((http|https)+:\/\/(www\.)?)/i, '').replace(/\.\w{2,4}\/?$/i, '')}</a> : ''}
				</div>
				<div>{(platform.country) ?? '-'}</div>
				<div>{(platform.platformType) ? locale[platform.platformType] : '-'}</div>
				<div>{(platform.language)?? '-'}</div>
				<div>{(platform.period.startDate) ? stDate : '-'}</div>
				<div>{statuses[platform.id] && statuses[platform.id] === 'Enabled'
					? <span className="text--green">Вкл.</span>
					: statuses[platform.id]
						? <span className="text--light_gray">Выкл.</span>
						: <Skeleton width={'46px'}/>
				}</div>
			</div>
		})
	}

	return <div className={"platforms-table-list grid-table"}>
		{makeHeaad()}

		{platforms
			? makeList()
			: <Skeleton width={"100%"} height={"20px"}/>
		}
	</div>
}

export default PlatformTableList
import {gql} from "@apollo/client";
import {clearTime, getDay} from "../functions/datesHelper";
import {CONCEPT_SHORT_INFO} from "./fragments";

export const DOCUMENT_STAT_TEMPLATE = gql`
	query StoriesStat($filterSettings: DocumentFilterSettings){
		paginationStory(
			limit: 0
			extraSettings: {}
			filterSettings: $filterSettings
		  ) {
			showTotal
			__typename
		  }
	}
`
export const DOCUMENT_STAT = gql`
	query DocumentsStat($d0start: UnixTime, $d0end: UnixTime, $d1start: UnixTime, $d2start: UnixTime, $d3start: UnixTime, $d4start: UnixTime, $d5start: UnixTime, $d6start: UnixTime,){
		storyDay0: paginationStory(limit: 1, extraSettings: {}, filterSettings: {registrationDate: {start: $d0start, end: $d0end}}){
			showTotal
		}
		storyDay1: paginationStory(limit: 1, extraSettings: {}, filterSettings: {registrationDate: {start: $d1start, end: $d0start}}){
			showTotal
		}
		storyDay2: paginationStory(limit: 1, extraSettings: {}, filterSettings: {registrationDate: {start: $d2start, end: $d1start}}){
			showTotal
		}
		storyDay3: paginationStory(limit: 1, extraSettings: {}, filterSettings: {registrationDate: {start: $d3start, end: $d2start}}){
			showTotal
		}
		storyDay4: paginationStory(limit: 1, extraSettings: {}, filterSettings: {registrationDate: {start: $d4start, end: $d3start}}){
			showTotal
		}
		storyDay5: paginationStory(limit: 1, extraSettings: {}, filterSettings: {registrationDate: {start: $d5start, end: $d4start}}){
			showTotal
		}
		storyDay6: paginationStory(limit: 1, extraSettings: {}, filterSettings: {registrationDate: {start: $d6start, end: $d5start}}){
			showTotal
		}
	}
`
export const PLATFORMS_STAT = gql`
	query PlatformsStat($d0start: UnixTime, $d0end: UnixTime, $d1start: UnixTime, $d2start: UnixTime, $d3start: UnixTime, $d4start: UnixTime, $d5start: UnixTime,){
		platformDay0: paginationPlatform(limit: 1,  filterSettings: {registrationDate: {start: null, end: $d0end}}){
		    total
		}
		platformDay1: paginationPlatform(limit: 1,  filterSettings: {registrationDate: {start: null, end: $d0start}}){
		    total
		}
		platformDay2: paginationPlatform(limit: 1,  filterSettings: {registrationDate: {start: null, end: $d1start}}){
		    total
		}
		platformDay3: paginationPlatform(limit: 1,  filterSettings: {registrationDate: {start: null, end: $d2start}}){
		    total
		}
		platformDay4: paginationPlatform(limit: 1,  filterSettings: {registrationDate: {start: null, end: $d3start}}){
		    total
		}
		platformDay5: paginationPlatform(limit: 1,  filterSettings: {registrationDate: {start: null, end: $d4start}}){
		    total
		}
		platformDay6: paginationPlatform(limit: 1,  filterSettings: {registrationDate: {start: null, end: $d5start}}){
		    total
		}
	}
`

export const DOCUMENT_AND_STORIES_STAT = gql`
	query DocumentsCount{
		paginationStory(limit:0, extraSettings:{}, filterSettings:{tdmProcessStage: all}){
			showTotal
		}
		paginationPlatform(limit:0, filterSettings:{}){
			total
		}
	}
`
const d = new Date()

export const weekTime = [
	{end: d.getTime(), start: clearTime(d).getTime()},
	{end: clearTime(d).getTime(), start: getDay(d, -7)},
	{end: getDay(d, 0), start: getDay(d, -7)},
	{end: getDay(d, 0), start: getDay(d, -7)},
	{end: getDay(d, 0), start: getDay(d, -7)},
	{end: getDay(d, 0), start: getDay(d, -7)},
	{end: getDay(d, 0), start: getDay(d, -7)},
]

export const SMART_SEARCH = gql`
    query ConceptSmartSearch($query: String){
        paginationConcept(limit: 20, sortField: score, direction:  ascending, filterSettings:{substring: $query, status: approved, conceptVariant: [obj]}){
            listConcept{
                ...ConceptShortInfo
            }
        }
    }
    ${CONCEPT_SHORT_INFO}
`


import React from "react";
import {HandySvg} from 'handy-svg';
import Modal from "./Modal";
import {useState} from "react";

import tooltip_ico from '../../ico/tooltip.svg';

const Tooltip = ({children}) => {
	const [tooltipState, setTooltipState] = useState(false);

	return (
		<div className="tooltip">
			<div className="tooltip__ico" onClick={(e)=>{setTooltipState(true)}}>
				<HandySvg src={tooltip_ico} width={28} height={28}></HandySvg>
			</div>
			<Modal className="tooltip__content" opened={tooltipState} setOpened={setTooltipState}>
				{children}
			</Modal>
		</div>
	)
}

export default Tooltip;
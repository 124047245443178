import possibleTypes from './graphql/possibleTypes.json'

// export const TALISMAN_HOST = 'https://mediadata.talisman.ispras.ru'
export const TALISMAN_HOST = 'https://mgimo.talisman.ispras.ru'
// export const TALISMAN_HOST = 'https://demo.talisman.ispras.ru'

let backHost = 'https://talismanmgimo-dev.ispras.ru'
let frontHost = 'https://talismanmgimo-dev.ispras.ru'
if(process.env.NODE_ENV === 'development'){//todo: поменять в проде
	backHost = 'https://talismanmgimo-dev.ispras.ru'
	frontHost = 'https://talismanmgimo-dev.ispras.ru'
}else if(process.env.NODE_ENV === 'production'){
	// backHost = 'https://talismanmgimo-dev.ispras.ru'
	// frontHost = 'https://talismanmgimo-dev.ispras.ru'
	backHost = 'https://talismanmgimo-dev.ispras.ru'
	frontHost = 'https://talismanmgimo-dev.ispras.ru'
}

export const BACK_HOST = backHost
export const FRONT_HOST = frontHost

export const POSSIBLE_TYPES = possibleTypes;

export const CALENDAR_LOCALE = {
	// months list by order
	months: [
		'Январь',
		'Февраль',
		'Март',
		'Апрель',
		'Май',
		'Июнь',
		'Июль',
		'Август',
		'Сентябрь',
		'Октябрь',
		'Ноябрь',
		'Декабрь',
	],

	// week days by order
	weekDays: [

		{
			name: 'Понедельник',
			short: 'Пн',
		},
		{
			name: 'Вторник',
			short: 'Вт',
		},
		{
			name: 'Среда',
			short: 'Ср',
		},
		{
			name: 'Чертвер',
			short: 'Чт',
		},
		{
			name: 'Пятница',
			short: 'Пт',
		},
		{
			name: 'Суббота',
			short: 'СБ',
			isWeekend: true,
		}, {
			name: 'Воскресенье', // used for accessibility
			short: 'Вс', // displayed at the top of days' rows
			isWeekend: true, // is it a formal weekend or not?
		},
	],

	// just play around with this number between 0 and 6
	weekStartingIndex: 6,

	// return a { year: number, month: number, day: number } object
	getToday(gregorainTodayObject) {
		return gregorainTodayObject;
	},

	// return a native JavaScript date here
	toNativeDate(date) {
		return new Date(date.year, date.month - 1, date.day);
	},

	// return a number for date's month length
	getMonthLength(date) {
		return new Date(date.year, date.month, 0).getDate();
	},

	// return a transformed digit to your locale
	transformDigit(digit) {
		return digit;
	},


	// for input range value
	from: 'От',
	to: 'До',


	// used for input value when multi dates are selected
	digitSeparator: ',',

	// if your provide -2 for example, year will be 2 digited
	yearLetterSkip: 0,

	// is your language rtl or ltr?
	isRtl: false,
}

// const getRandomInt = (max) => {
// 	return Math.floor(Math.random() * max);
// }
export const sankeyColors = [
	"#1E3178",
	"#BB5264",
	"#08934A",
	"#c35ff3",
	"#4c69fc",
	"#632CB9",
	"#B30054",
	"#8B7E0A",
	"#07CE27",
	"#592079",
	"#465211",
	"#0d797c",
	"#2024EC",
	"#6521F2",
	"#2BD534",
	"#a2fd45",
	"#27B134",
	"#75ea74",
	"#453136",
	"#35b99a",
	"#55307D",
	"#729271",
	"#C6ED18",
	"#A945DD",
	"#725E88",
	"#4FB16F",
	"#436af6",
	"#1012EA",
	"#00b6a0",
	"#00444b",
	"#365502",
	"#750540",
	"#9915B5",
	"#88EC16",
	"#4779E9",
	"#7CE378",
	"#F9A9FC",
	"#500983",
	"#CDB369",
	"#CE70D4",
	"#3BFFB1",
	"#a331f6",
	"#45B02E",
	"#E4375A",
	"#143360",
	"#65BEC5",
	"#f686ec",
	"#753212",
	"#E4BE00",
	"#17F405",
	"#D6E9A5",
	"#D2A2DA",
	"#F80CFB",
	"#DBEB83",
	"#928670",
	"#D63795",
	"#E677F4",
	"#BEC92D",
	"#A648B6",
	"#E2A069",
	"#0493B0",
	"#BC3530",
	"#9A1969",
	"#14E00B",
	"#9313C4",
	"#A442AB",
	"#363016",
	"#EF2694",
	"#5963C2",
	"#CAB1C4",
	"#9DFBBF",
	"#FCF27F",
	"#EF3B84",
	"#E89033",
	"#FE3DE9",
	"#CF0D30",
	"#A719AF",
	"#A7D9C1",
	"#ec4169",
	"#04b23b",
	"#F73E16",
	"#3DCD5A",
	"#0AF228",
	"#6228D6",
	"#188652",
	"#A84D9E",
	"#76CB04",
	"#CF5BFE",
	"#A65F1A",
	"#021f95",
	"#42A485",
	"#084828",
	"#412F0E",
	"#B17D57",
	"#DF4F58",
	"#50B212",
	"#49877F",
	"#0D4F7D",
	"#66B122",
	"#37AA88"
]
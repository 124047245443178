import {analyticsBACK, APIClient} from "../ApiEndpoints";
import {PLATFORM_PAGE, PLATFORM_STATUS} from "../graphql/platformsQueries";

export const getSavedPlatforms = (userID) => {
	return analyticsBACK.get('/platforms', {
		params: {
			userID: userID,
		}
	})
}

export const getPlatformStatus = (id) => {
	return APIClient.query({
		query: PLATFORM_STATUS,
		variables: {
			input: id
		}
	})
}

export const deletePlatformList = ({userID, platformListID}) => {
	return analyticsBACK.delete('/platforms', {
		params: {
			userID: userID,
			platformID: platformListID
		}
	})
}

export const getPlatformsByIDs = (IDs) => {
	return APIClient.query({
		query: PLATFORM_PAGE,
		variables: {
			filterSettings: {
				ids: IDs
			},
			limit: 500
		}
	})
}
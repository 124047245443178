import React from "react";
import {useSelector} from "react-redux";

const RememberList = ({children, title = "Избранные концепты:"}) => {
	const user = useSelector(state => state.user.value)

	if(user && user.group && user.group.code === 'outer'){// не выводим этот блок для внешних пользователей
		return <></>
	}

	return (
		<div className="remember-list">
			<div className="title c-main-dark">{title}</div>
			<ul>
				{children}
			</ul>
		</div>
	)
}

export default RememberList
import {gql} from "@apollo/client";
import {
    ACCESS_LEVEL_INFO,
    CONCEPT_TYPE_INFO,
    DOC_METRIC_INFO,
    DOC_TEXT_FRAGMENT,
    DOCUMENT_INFO,
    DOCUMENT_META_INFO
} from "./fragments";

export const PLATFORM_SEARCH = gql`
    query PlatformSearch($q: String){
        paginationPlatform(filterSettings:{searchString: $q}){
            total
            listPlatform{
                id
                name
                country
            }
        }
    }
`
export const PLATFORM_SEARCH_COUNTRIES = gql`
    query PlatformSearchByCountries($q: String, $countries: [String!]){
        paginationPlatform(filterSettings:{searchString: $q, country: $countries}){
            total
            listPlatform{
                id
                name
                country
            }
        }
    }
`

export const COUNTRIES_LIST = gql`
    query Countries{
        paginationCountry(filterSettings: {}, limit: 500){
            total
            listCountry
        }
    }
`

export const STORIES_BY_CONCEPTS_ALL_PLATFORMS = gql`
    query StoriesAllPlatforms($concepts: [ID!], $limit: Int, $offset: Int){
        paginationStory(filterSettings:{concepts: $concepts, childVisibility: all}, limit: $limit, offset: $offset, extraSettings:{}){
            showTotal
            listPlatformCountFacet{
              value {
                id
                name
                country
              }
              count
            }
            listStory{
                main{
                    ...DocInfo
                    metadata{
                        account{
                            id
                            name
                            url
                        }
                        platform{
                            id
                            name
                            country
                        }
                    }
                }
            }
        }
    }
    ${DOCUMENT_INFO}
    ${DOC_METRIC_INFO}
`

export const DOCUMENT_TEXT = gql`
query DocumentText($id: ID!){
  document(id:$id){
    ...DocInfo
    metadata{
        account{
            id
            name
            url
        }
        platform{
            id
            name
            country
        }
    }
  }
}
${DOCUMENT_INFO}
`

export const STORIES_BY_QUERY_AND_PLATFORMS = gql`
    query StoriesByQueryPlatforms($limit: Int = 20, $offset: Int = 0, $FS: DocumentFilterSettings = {}) {
        paginationStory(
            filterSettings: $FS,
            limit: $limit, 
            offset: $offset,
            direction: descending
            sortField: registrationDate
            extraSettings:{},
            grouping: none,
            relevance: {}
            ){
            showTotal
            listStory{
                main{
                    ...DocInfo
                    ...DocumentMetaInfo
                }
            }
        }
    }
    ${DOCUMENT_INFO}
    ${DOCUMENT_META_INFO}
`

export const STORIES_BY_CONCEPTS_ALL_PLATFORMS_DATES = gql`
    query StoriesAllPlatforms($concepts: [ID!], $limit: Int, $offset: Int, $date_start: UnixTime, $date_end: UnixTime){
        paginationStory(filterSettings:{concepts: $concepts, publicationDate:{start: $date_start, end: $date_end}, childVisibility: all}, limit: $limit, offset: $offset, extraSettings:{}){
            showTotal
            listPlatformCountFacet{
              value {
                id
                name
                country
              }
              count
            }
            listStory{
                main{
                    ...DocInfo
                    metadata{
                        platform{
                            id
                            name
                            country
                        }
                    }
                }
            }
        }
    }
    ${DOCUMENT_INFO}
    ${DOC_METRIC_INFO}
`

export const STREAM_STORIES_STATISTIC_BY_PLATFORMS = gql`
    query StreamStoriesStatByPlatforms($concepts: [ID!] = null, $conceptsCandidate: [ID!] = null, $q: String, $platforms: [ID!],$date0: UnixTime, $date1: UnixTime, $date2: UnixTime, $date3: UnixTime, $date4: UnixTime, $date5: UnixTime, ,$date6: UnixTime, $date7: UnixTime, $date8: UnixTime, $date9: UnixTime, $date10: UnixTime, $date11: UnixTime, $date12: UnixTime){
        DocsDate_0: paginationStory(extraSettings:{},limit: 1, filterSettings:{searchString: $q, platforms: $platforms,documentContentType: text, publicationDate: {start: $date1, end: $date0}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_1: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date2, end: $date1}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_2: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date3, end: $date2}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_3: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date4, end: $date3}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_4: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date5, end: $date4}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_5: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date6, end: $date5}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_6: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date7, end: $date6}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_7: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date8, end: $date7}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_8: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date9, end: $date8}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_9: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date10, end: $date9}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_10: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date11, end: $date10}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_11: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date12, end: $date11}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
    }
`

export const STREAM_STORIES_STATISTIC_TEMPLATE = gql`
query StreamStoriesStatByPlatforms($filter: DocumentFilterSettings = {}){
    paginationStory(extraSettings:{},limit: 0, filterSettings: $filter){
        total
        showTotal
    }
}
`

export const STREAM_STORIES_STATISTIC_BY_PLATFORMS_7_SLICES = gql`
    query StreamStoriesStatByPlatforms($concepts: [ID!] = null, $conceptsCandidate: [ID!] = null, $q: String, $platforms: [ID!],$date0: UnixTime, $date1: UnixTime, $date2: UnixTime, $date3: UnixTime, $date4: UnixTime, $date5: UnixTime, ,$date6: UnixTime, $date7: UnixTime){
        DocsDate_0: paginationStory(extraSettings:{},limit: 1, filterSettings:{searchString: $q, platforms: $platforms,documentContentType: text, publicationDate: {start: $date1, end: $date0}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_1: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date2, end: $date1}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_2: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date3, end: $date2}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_3: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date4, end: $date3}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_4: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date5, end: $date4}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_5: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date6, end: $date5}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_6: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date7, end: $date6}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
    }
`
export const STREAM_STORIES_STATISTIC_BY_PLATFORMS_4_SLICES = gql`
    query StreamStoriesStatByPlatforms($concepts: [ID!] = null, $conceptsCandidate: [ID!] = null, $q: String, $platforms: [ID!],$date0: UnixTime, $date1: UnixTime, $date2: UnixTime, $date3: UnixTime, $date4: UnixTime){
        DocsDate_0: paginationStory(extraSettings:{},limit: 1, filterSettings:{searchString: $q, platforms: $platforms,documentContentType: text, publicationDate: {start: $date1, end: $date0}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_1: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date2, end: $date1}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_2: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date3, end: $date2}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
        DocsDate_3: paginationStory(extraSettings:{}, limit: 1, filterSettings:{searchString: $q, platforms: $platforms ,documentContentType: text, publicationDate: {start: $date4, end: $date3}, concepts: $concepts, meaningConceptCandidates: $conceptsCandidate}){
            total
            showTotal
        }
    }
`

const CONCEPT_INLINE = gql`
    fragment ConceptInline on Concept {
      id
      name
      status
      image {
        url
        thumbnail
        __typename
      }
      accessLevel {
        ...AccessLevelInfo
        __typename
      }
      conceptType {
        ...ConceptTypeInline
        __typename
      }
      __typename
    }
`

const STORY_CONCEPT_FACET = gql`
    fragment StoryConceptFacet on Concept {
      id
      ...ConceptInline
      metric {
        countPotentialDocuments
        __typename
      }
      __typename
    }
`

export const STREAM_FACET = gql`
    query PaginationStoryConceptFacet($limit: Int = 20, $offset: Int, $grouping: DocumentGrouping, $sortDirection: SortDirection, $sortField: DocumentSorting, $filterSettings: DocumentFilterSettings, $extraSettings: ExtraSettings = {}, $relevance: DocumentRelevanceMetricsInput) {
      paginationStory(
        limit: $limit
        offset: $offset
        grouping: $grouping
        direction: $sortDirection
        sortField: $sortField
        filterSettings: $filterSettings
        extraSettings: $extraSettings
        relevance: $relevance
      ) {
        listConceptCountFacet {
          value {
            ...StoryConceptFacet
            __typename
          }
          count
          __typename
        }
        __typename
      }
    }
    
    ${CONCEPT_TYPE_INFO}
    ${ACCESS_LEVEL_INFO}
    ${CONCEPT_INLINE}
    ${STORY_CONCEPT_FACET}
`

export const STREAM_NAMED_ENTITY_FACET = gql`
    query PaginationStoryNamedEntityFacet($limit: Int, $offset: Int, $grouping: DocumentGrouping, $sortDirection: SortDirection, $sortField: DocumentSorting, $filterSettings: DocumentFilterSettings, $extraSettings: ExtraSettings = {}, $relevance: DocumentRelevanceMetricsInput) {
      paginationStory(
        limit: $limit
        offset: $offset
        grouping: $grouping
        direction: $sortDirection
        sortField: $sortField
        filterSettings: $filterSettings
        extraSettings: $extraSettings
        relevance: $relevance
      ) {
        listNamedEntityCountFacet {
          value
          count
          __typename
        }
        __typename
      }
    }
`
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Skeleton from "react-loading-skeleton";
import {APIClient} from "../../ApiEndpoints";
import PlatformTableList from "../../components/platforms/PlatformTableList";
import {PLATFORM_PAGE} from "../../graphql/platformsQueries";
import {deletePlatformList, getSavedPlatforms} from "../../requests/platforms";
import {setSavedPlatforms} from "../../reducers/platformSlice";

const SavedPlatforms = () => {
	useEffect(() => { // устанавливаем заголовки
		document.title = 'Мгимо аналитика: Список избранных источников'
	}, [])
	const user = useSelector(state => state.user.value)
	const [fetching, setFetching] = useState(false)
	// const [platforms, setPlatforms] = useState([])
	const [fetchError, setFetchError] = useState({})
	const [deleting, setDeleting] = useState([])
	const [detailList, setDetailList] = useState([])
	const [fetchDetail, setFetchDetail] = useState(false)
	const modalRef = useRef()
	const savedPlatforms = useSelector((state) => state.platform.saved)
	const dispatch = useDispatch()

	useEffect(() => { //загружаем сохраненные источники
		if(user.id){
			if(savedPlatforms.length === 0){// если платформ в магазине нет, то получаем их
				setFetching(true)
				setFetchError({})
				getSavedPlatforms(user.id)
					.then((resp) => {
						if(resp.data.platformsList) {
							dispatch(setSavedPlatforms(resp.data.platformsList))
						}
					})
					.catch((resp) => {
						// console.log('RESP', resp);
						setFetchError({init: resp.data.message})
					})
					.finally(() => setFetching(false))
			}
		}
	}, [user])

	const deleteList = (id) => {
		setDeleting([...deleting, id])
		deletePlatformList({userID: user.id, platformListID: id})
			.then((resp) => {
				dispatch(setSavedPlatforms(resp.data.platformsList))
			})
			.catch((resp) => {
				setFetchError({del: resp.data.message})
			})
			.finally(() => setDeleting( (prev) => { return prev.filter( (itm) => itm !== id ) } ))
	}

	const makeHeaad = () => {
		return <div className="grid-table__row platforms-table-list__head-item">
			<div>Название списка источников</div>
			<div className='centred'>Дата<br/>
				создания
			</div>
			<div className="centred">Количество<br/>
				источников
			</div>
			<div></div>
		</div>
	}

	const fetchList = (platforms) => {
		setFetchDetail(true)
		setDetailList([])
		modalRef.current.classList.add('active')

		// console.log('platforms', platforms)
		APIClient.query({
			query: PLATFORM_PAGE,
			variables:{
				limit: 10000,
				filterSettings: {
					ids: platforms.split('|')
				}
			}
		})
			.then((resp) => {
				if(resp.data && resp.data.paginationPlatform && resp.data.paginationPlatform.listPlatform)
				setDetailList(resp.data.paginationPlatform.listPlatform)
			})
			.catch((resp) => {
				fetchError({detail: 'Не удалось получить список'})
			})
			.finally(() => setFetchDetail(false))
	}

	const makeList = () => {
		if(savedPlatforms.length === 0){
			return <div className="grid-table__row"><p>Сохраненных источников пока нет</p></div>
		}

		return savedPlatforms.map((platform) => {
			const created = new Date(platform.created_at)
			const dateString = created.toLocaleDateString("ru", {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
			})
			const timeString = created.toLocaleTimeString("ru", { hour: "2-digit", minute: "2-digit"})

			return <div key={platform.id} className="grid-table__row">
				<div className='title c-main-dark js-show-detail' onClick={() => fetchList(platform.platformID)}>{platform.name}</div>
				<div className='centred'>
					{dateString} <br />
					<span className="c-light-gray">{timeString}</span>
				</div>
				<div className='centred'>{(platform.platformID)? platform.platformID.split('|').length : 0}</div>
				{deleting.includes(platform.id)
					? <Skeleton circle={true} baseColor={'#C0435A'} height={'20px'} width={'20px'}/>
					:<div className='flex_row c-gray-bg flex_row--sp_center js-delete' onClick={() => deleteList(platform.id)}>
						<div className='cross'></div>
						<p>Удалить</p>
					</div>
				}
			</div>
		})
	}

	const makeSkelet = () => {
		return <div className="grid-table__row">
			<Skeleton containerClassName='title  c-main-dark' height={'28px'}
			          baseColor={'#5038EC'}
			          width={50 + Math.floor(Math.random() * 450)}/>
			<Skeleton containerClassName={'centred'} width={50 + Math.floor(Math.random() * 50)}/>
			<Skeleton containerClassName={'centred'} width={50 + Math.floor(Math.random() * 50)}/>
			<Skeleton width={'60px'}/>
		</div>
	}

	const makeDummy = () => {// показываем прелоадер, если загружаем данные
		return <div className="platforms_page">

			<div className={"saved_platforms_list__table grid-table"}>
				{makeHeaad()}
				{makeSkelet()}
				{makeSkelet()}
				{makeSkelet()}
				{makeSkelet()}
				{makeSkelet()}
				{makeSkelet()}
			</div>
		</div>
	}

	return <div className="main-content">
		<div className='content visible'>
			<div className="title-big c-main-dark">Список избранных источников</div>
			<div>&nbsp;</div>
			{fetchError.init &&
				<div className={'error'}>{fetchError.init}</div>
			}
			{fetchError.del &&
				<div className={'error'}>{fetchError.del}</div>
			}

			{fetching
				? makeDummy()
				: <div className="saved_platforms_list">
					<div className={"saved_platforms_list__table grid-table"}>
						{makeHeaad()}
						{makeList()}
					</div>
				</div>
			}
		</div>

		<div className="modal_holder" ref={modalRef}>
			<div className="modal_bg" onClick={()=>modalRef.current.classList.remove('active')}></div>
			<div className="modal_content modal_content__wide content">
				<div className='modal_close' onClick={()=>modalRef.current.classList.remove('active')}>
					<p onClick={()=>modalRef.current.classList.toggle('active')}></p>
					<div className="cross" onClick={()=>modalRef.current.classList.remove('active')}></div>
				</div>

				<div className="modal_table">
					{fetchDetail
						?<div className='flex_row flex_row--sp_center' style={{height: '200px'}}>
							<div className='mini-loader' />
						</div>
						: <PlatformTableList platforms={detailList} saved={true} />
					}
				</div>
			</div>
		</div>
	</div>
}

export default SavedPlatforms
import LoaderPage from "../../components/laders/LoaderPage";
import authLogo from "../../img/auth-logo.svg";
import authText from "../../ico/logo_autorization.svg";
import React, {useState} from "react";
import SmallButton from "../../components/ui/buttons/SmallButton";
import {analyticsBACK} from "../../ApiEndpoints";
import FormErrors from "../../components/ui/FormErrors";
import {Link, useParams} from "react-router-dom";

export default function PassRestoreRequest(){
    const [formBusy, setFormBusy] = useState(false)
    const [login, setLogin] = useState('')
    const [successMSG, setSuccessMSG] = useState('')
    const [errors, setErrors] = useState([])
    const sendRequest = (e) => {
        e.preventDefault()
        setErrors([])
        setFormBusy(true)
        setSuccessMSG('')
        analyticsBACK.post('/call-password-restore', {login})
            .then((resp) => {
                setSuccessMSG('Письмо с восстановлением пароля отправлено на ваш емейл.')
            })
            .catch((resp) => {
                console.error('REQ_FAIL')
                setErrors(['Ошибка при попытки восстановления пароля. ' + ((resp.response.data && resp.response.data.message)? resp.response.data.message : '')])
            })
            .finally(() => {setFormBusy(false)})
    }

    return <div className="auth-page">
        <div className="content visible">
            <LoaderPage loading=""/>
            <div className="auth-page-content">
                <div className="auth-page-content__left">
                    <Link to='/'><img src={authLogo} alt="" className="auth-page-logo"/></Link>
                </div>
                <div className="auth-page-content__right">
                    <Link to={"/"}><img src={authText} alt="" className="auth-page-text"/></Link>

                    <div className="title">
                        Введите емейл к которому привязана учетная запись
                    </div>
                    <p>{successMSG}</p>
                    <FormErrors errors={errors} />
                    <form action="" onSubmit={(e) => sendRequest(e)} aria-disabled={formBusy} className="nice-form js-auth-form mt__normal">
                        <div className="cols cols-2 p10">
                            <div className="col">
                                <label className="input-wrap">
                                    <input
                                        type="email"
                                        name={'email'}
                                        placeholder=" "
                                        onInput={e => setLogin(e.target.value)}
                                        value={login}
                                        required={true}
                                    />
                                    <div className="input-wrap__label">
                                        Email
                                    </div>
                                </label>
                                <SmallButton>Отправить</SmallButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
}
import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./reducers/userSlice";
import conceptSlice from "./reducers/conceptSlice";
import statisticSlice from "./reducers/statisticSlice";
import streamsSlice from "./reducers/streamsSlice";
import platformSlice from './reducers/platformSlice'
import appSlice from "./reducers/appSlice";

export default configureStore({
	reducer: {
		user: userSlice,
		concepts: conceptSlice,
		statistic: statisticSlice,
		stream: streamsSlice,
		platform: platformSlice,
		app: appSlice
	},
})
import React, {useState} from "react"
import  "../../styles/pages/peronal.sass"
import {useSelector} from "react-redux";

const PersonalInvite = React.lazy(() => import("../../components/personal/PersonalInvite"))
const InvitedUsers = React.lazy(() => import('../../components/personal/InvitedUsers'))
const PersonalInformation = React.lazy(() => import('../../components/personal/PersonalInformation'))
export default function (){
    const [invite, setInvite] = useState()
    const currentUser = useSelector(state => state.user.value)

    return <div className="main-content content visible">
        <h1 className="title-big main-margin c-main-dark">Персональный кабинет</h1>

        <PersonalInformation />

        {currentUser.group.id === 1 &&
            <>
                <PersonalInvite invite={invite} setInvite={setInvite} />
                <InvitedUsers invite={invite} />
            </>
        }
    </div>
}
import {gql} from "@apollo/client"
import {PLATFORM_INFO} from "./fragments";

export const PLATFORM_TYPES_LIST = gql`
	query getEnumValues {
	  __type(name: "PlatformType") {
	    enumValues {
	      name
	      __typename
	    }
	    __typename
	  }
	}
`

export const PLATFORM_PAGE = gql`
	query paginatedPlatforms($filterSettings: PlatformFilterSettings = {}, $sortDirection: SortDirection = descending, $sortField: PlatformSorting = name, $limit: Int = 20, $offset: Int = 0) {
	  paginationPlatform(
	    filterSettings: $filterSettings
	    direction: $sortDirection
	    sortField: $sortField
	    limit: $limit
	    offset: $offset
	  ) {
	    total
	    listPlatform {
	      ...PlatformInfo
	      __typename
	    }
	    __typename
	  }
	}
	
	${PLATFORM_INFO}
`

export const PLATFORM_STATUS = gql`
	query getPeriodicJobs($input: String) {
	  paginationPeriodicJob(
	    offset: 0
	    limit: 1
	    filterSettings: {inputValue: $input}
	    direction: null
	    sortField: null
	  ) {
	    total
	    listPeriodicJob {
	      id
	      name
	      status
	      __typename
	    }
	    __typename
	  }
	}
`
import React, {useEffect, useState} from "react"
import Header from "./Header";
import Footer from "./Footer";
import {useDispatch, useSelector} from "react-redux";
import {analyticsBACK} from "../../ApiEndpoints";
import {setAuthData, setUserData} from "../../reducers/userSlice";
import LoaderPage from "../laders/LoaderPage";
import AuthPage from "../../routes/Auth/AuthPage";
import useAsyncDispatcher from "../../Dispatcher/useAsyncDispatcher";

const MainTheme = ({children}) => {
	useAsyncDispatcher({action: 'getAppVersion', state: 'app.appVersion'})
	useAsyncDispatcher({action: 'getBackVersion', state: 'app.backVersion'})
	const userAuth = useSelector((state) => state.user.auth)
	const [authCheck, setAuthCheck] = useState(false)
	const dispatch = useDispatch()

	useEffect(() => { //refreshing token if user authorized
		let check
		if (userAuth) {
			analyticsBACK.get('/keep-alive')
				.catch((resp) => {
					setUserData(false)
					dispatch(setAuthData(null))
				})
			check = setInterval(() => {
				if (userAuth) {
					analyticsBACK.get('/keep-alive')
						.then((resp) => {
							analyticsBACK.get('/expired')
								.catch((resp) => {
									console.error("EXPIRED")
								})
						})
						.catch((resp) => {
							console.error('REFRESHING_TOKEN_FAIL')
							setUserData(false)
							dispatch(setAuthData(null))
						})
				}
			}, 100000)
		}

		return () => clearInterval(check);
	}, [userAuth])

	useEffect(() => { //auth check on page load if not checked
		if(!authCheck){
			analyticsBACK.get('/expired')
				.then((resp) => {
					analyticsBACK.get('/getCurrentUser')
						.then((resp) => {
							if(resp && resp.data && resp.data.data && resp.data.data.currentUser){
								dispatch(setAuthData(true))
								dispatch(setUserData(resp.data.data.currentUser))
							}
							setAuthCheck(true)
						})
						.catch((resp) => {
							console.error('server vas gone away')
							setAuthCheck(true)
							dispatch(setAuthData(false))
						})
				})
				.catch((resp) => {
					console.error("Auth expired");
					dispatch(setAuthData(null))
					dispatch(setUserData(null))
					if(resp && resp.request && resp.request.status === 500){
						console.error('server vas gone away')
						setAuthCheck(true)
						dispatch(setAuthData(false))
					}else{
						setAuthCheck(true)
					}
				})
		}
	}, [])

	return <div className="wrapper">
		<LoaderPage loading={(authCheck)? '' : "loading"} />
		{authCheck &&
			userAuth
				?<>
					<Header />
					{children}
					<Footer />
				</>
				:<AuthPage />
		}
	</div>
}

export default MainTheme
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import ListBlock from "../../components/ui/listBlock/ListBlock";
import "../../styles/pages/main.sass"
import {useSelector} from "react-redux";

const MainAnalytics = React.lazy(() => import("../../components/mainPage/MainAnalytics"))

const Main = () => {
	const user = useSelector(state => state.user.value)
	useEffect(() => {
		document.title = 'Мгимо аналитика'
	}, [])
	return <main className='main-content content visible'>
		<div className="main-analytic">

			<div className={"main_buttons flex_row flex_row--wrap flex_row--gap_h_double flex_row--gap_w_double flex_row--sp_center"}>

				{user && user.group.code == 'outer'
					? <>
						<div className="col">
							<Link to="/stream" className="btn">Сформировать ленту</Link>
							<div className="main-analytic-desc">
								Настроить и сохранить автоматически обновляемую ленту новостей из заданных источников по заданной в поисковом запросе теме.
							</div>
						</div>
					</>
					: <>
						<div className="col">
							<Link to="/concept" className="btn">Сформировать досье</Link>
							<div className="main-analytic-desc">
								Получить сведения о концепте, занесенные в базу знаний, включая связи с другими концептами, статистику упоминаний в источниках.
							</div>
						</div>

						{/*<div className="col">*/}
						{/*	<Link to="/connections" className="btn">Построить связи объектов</Link>*/}
						{/*	<div className="main-analytic-desc">*/}
						{/*		Получить сеть прямых и опосредованных связей одного, двух или более объектов, занесенных в базу знаний, статистику упоминаний связанных объектов.*/}
						{/*	</div>*/}
						{/*</div>*/}

						<div className="col">
							<Link to="/stream" className="btn">Сформировать ленту</Link>
							<div className="main-analytic-desc">
								Настроить и сохранить автоматически обновляемую ленту новостей из заданных источников по заданной в поисковом запросе теме.
							</div>
						</div>


						<div className="col">
							<Link to="/stream/static" className="btn">Сформировать поиск</Link>
							<div className="main-analytic-desc">
								Поиск материалов с заданным временным интервалом.
							</div>
						</div>
					</>
				}
			</div>

			<div className="intro">
				<h1 className={'title'}>Цели и задачи интерфейса МГИМО ANALYTICS</h1>
				<div className={'flex_row flex_row--gap_w_double'}>
					<div className={'col main-analytic-desc'}>
						Современные информационные технологии предоставляют мощные инструменты для поиска и анализа материалов СМИ. Интерфейс для работы с такими данными должен быть интуитивно понятным и функциональным, обеспечивая пользователей необходимыми инструментами для быстрого доступа к информации.
					</div>

					<div className={'col main-analytic-desc'}>
						Такой интерфейс будет полезен не только журналистам и исследователям, но и маркетологам, PR-специалистам и всем, кто интересуется общественным мнением и медийными трендами. Он поможет в эффективном мониторинге новостей, анализе репутации брендов и выявлении актуальных тем для обсуждения.
					</div>
				</div>
			</div>
		</div>

		<MainAnalytics />

		<div className="list-block-wrap">
			<ListBlock
				title="Сформированные отчеты"
				link="/reports"
				link_name="Посмотреть все отчеты"
				type="files"
				limit={5}
			/>
			<ListBlock
				title="Журнал действий"
				link="/history"
				limit={5}
				link_name="Посмотреть все действия"
				type="journal"
			/>
		</div>
	</main>
}

export default Main
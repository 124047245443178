import {createSlice} from "@reduxjs/toolkit";

export const appSlice = createSlice({
	name: 'appSlice',
	initialState: {
		backVersion: '',
		appVersion: '',
	},
	reducers: {
		setAppVersion: (state, action) => {
			state.appVersion = action.payload
		},
		setBackVersion: (state, action) => {
			state.backVersion = action.payload
		}
	}
})

export const {
	setAppVersion,
	setBackVersion,
} = appSlice.actions

export default appSlice.reducer
import {useDispatch, useSelector} from "react-redux";
import {analyticsBACK, APIClient} from "../ApiEndpoints";
import {setAppVersion, setBackVersion} from "../reducers/appSlice";
import {
	setPlatformCountries,
	setPlatformsLocales,
	setPlatformsManifest,
	setPlatformTypes
} from "../reducers/platformSlice";
import {PLATFORM_TYPES_LIST} from "../graphql/platformsQueries";
import {COUNTRIES_LIST} from "../graphql/streamQueries";

export default function  Actions () {
	const dispatch = useDispatch()
	const store = useSelector((state) => state)
	const fetchings = {}

	return {
		"getAppVersion" : () => {
			if(store.app.appVersion !== 'fetching')
			{
				dispatch(setAppVersion('fetching'))
				fetch('/manifest.json')
					.then((res) => res.json())
					.then((data) => {
						if(data && data.version){
							dispatch(setAppVersion(data.version))
						}else{
							dispatch(setAppVersion('0'))
						}
					});
			}
		},

		"getBackVersion": () => {
			if(store.app.backVersion !== 'fetching') {
				dispatch(setBackVersion('fetching'))
				analyticsBACK.get('/version')
					.then((resp) => {
						if (resp && resp.data && resp.data.version) {
							dispatch(setBackVersion(resp.data.version))
						}
						else
							dispatch(setBackVersion('0'))
					})
			}
		},

		"getPlatformsManifest": () => {
			analyticsBACK.get('/platformsManifest')
				.then((resp) => {
					dispatch(setPlatformsManifest(resp.data))
				})
		},

		"getPlatformTypes": () =>{
			if(store.platform.types !== 'fetching') {
				dispatch(setPlatformTypes('fetching'))
				APIClient.query({query: PLATFORM_TYPES_LIST})
					.then((resp) => {
						if (resp.data && resp.data["__type"].enumValues) {
							dispatch(setPlatformTypes(resp.data["__type"].enumValues))
						}
					})
					.catch((resp) => {
						dispatch(setPlatformTypes('fail'))
						console.error("FETCHING_PLATFORMS_FAIL")
					})
			}
		},

		"getPlatformsLocales": () => {
			if(store.platform.locale !== 'fetching') {
				dispatch(setPlatformsLocales('fetching'))
				analyticsBACK.get('/getFile?url=/locales/ru/Platform.json')
					.then((resp) => {
						if (resp.data.PlatformType)
							dispatch(setPlatformsLocales(resp.data.PlatformType))
					})
					.catch((resp) => {
						dispatch(setPlatformsLocales('fail'))
						console.error('FETCHING_LOCALE_FAIL');
					})
			}
		},

		"getPlatformCountries": () => {
			dispatch(setPlatformCountries('fetching'))
			APIClient.query({
				query: COUNTRIES_LIST
			})
				.then(resp => {
					if (resp.data.paginationCountry.listCountry){
						dispatch(setPlatformCountries(resp.data.paginationCountry.listCountry))
					}else {
						dispatch(setPlatformCountries('fail'))
						console.error('FETCHING_PLATFORM_COUNTRIES_FAIL');
					}
				})
				.catch(resp => {
					dispatch(setPlatformCountries('fail'))
					console.error('FETCHING_PLATFORM_COUNTRIES_FAIL');
				})
		}
	}
}
import blankIco from '../../ico/_blank.svg'
import {HandySvg} from 'handy-svg';
import React from "react";
import {Link, useSearchParams} from "react-router-dom";
const StreamFacetItem = ({item, mode}) => {
    const [search, setSearch] = useSearchParams();
    let tql = ''

    search.delete('namedEntities')
    search.delete('concepts')
    // if(search.get('q')) {
    //     tql = JSON.stringify(JSON.parse(search.get('q')))
    //     search.delete('q')
    // }

    return <div className={"facet_item"} key={(mode === 'namedEntities')? item.value : item.value.id}>
        <div>
            {(mode === 'namedEntities')? item.value : item.value.name}
        </div>
        <div>
            ({item.count})
        </div>
        <div>

            <Link target={'_blank'} title={'Открыть в новом окне'} to={
                window.location.pathname + '?' + decodeURI(search.toString()) +
                '&' + ((mode === 'namedEntities')? 'namedEntities=' + item.value : 'concepts=' + item.value.id) +
                ((tql)? '&q=' + tql : '')
            }>
                <HandySvg src={blankIco} width={18} height={16} className="blank_ico"/>
            </Link>
        </div>
    </div>
}

export default StreamFacetItem
import React from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import FeedbackForm from "../ui/FeedbackForm";

function Footer() {
	const appVersion = useSelector((state) => state.app.appVersion)
	const backVersion = useSelector((state) => state.app.backVersion)

	return (
		<footer className="footer">
			<div className="content">
				<div className="footer-left">
					<div className="footer-itm">
						Необходимая правовая информация<br/>
						{new Date().getFullYear()} (с) ИСП РАН
						<br/>
						<FeedbackForm />
					</div>
				</div>
				<div className="footer-right">
					<Link to="/" className="footer-itm footer-itm--link">Пользовательское соглашение</Link><br/>
					<Link to="/" className="footer-itm footer-itm--link">Информация о хранении и обработке персональных данных</Link> <br />
					<span className='footer-itm'  style={{color: '#ddd'}}>Версия фронтенда: {appVersion}, версия бэкенда: {backVersion}</span>
				</div>
			</div>

		</footer>
	)
}

export default Footer;
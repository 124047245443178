import {createSlice} from "@reduxjs/toolkit";

export const statisticSlice = createSlice({
	name: 'statisticState',
	initialState: {
		monthStory: null
	},
	reducers: {
		setMonthStat: (state, action) => {
			state.monthStory = action.payload
		}
	}
})

export const { setMonthStat } = statisticSlice.actions

export default statisticSlice.reducer
import LoaderPage from "../../components/laders/LoaderPage";
import authLogo from "../../img/auth-logo.svg";
import authText from "../../ico/logo_autorization.svg";
import FormErrors from "../../components/ui/FormErrors";
import SmallButton from "../../components/ui/buttons/SmallButton";
import React, {useState} from "react";
import {analyticsBACK} from "../../ApiEndpoints";
import {Link, useParams} from "react-router-dom";

export default function PassRestoreForm() {
    const {secret} = useParams();
    const [formBusy, setFormBusy] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const [success, setSuccess] = useState(false)
    const [errors, setErrors] = useState([])

    const sendRequest = (e) => {
        e.preventDefault()

        if(password.length < 6){
            setErrors(['Минимальная длина пароля - 6 символов'])
            return ''
        }
        setErrors([])
        setFormBusy(true)
        setSuccess(false)
        analyticsBACK.post('/set-new-password', {password, passwordRepeat, pass: secret})
            .then((resp) => {
                setSuccess(true)
            })
            .catch((resp) => {
                console.error('RESTORE_ERROR')
                setErrors(['Ошибка при восстановлении пароля. ' + ((resp.response.data && resp.response.data.message)? resp.response.data.message : '')])
            })
            .finally(() => {setFormBusy(false)})
    }

    return <div className="auth-page">
        <div className="content visible">
            <LoaderPage loading=""/>
            <div className="auth-page-content">
                <div className="auth-page-content__left">
                    <Link to={"/"}><img src={authLogo} alt="" className="auth-page-logo"/></Link>
                </div>
                <div className="auth-page-content__right">
                    <Link to={"/"}><img src={authText} alt="" className="auth-page-text"/></Link>

                    <div className="title">
                        Введите новый пароль
                    </div>
                    {success &&
                        <p>Пароль успешно восстановлен. <Link to={'/'}>Авторизуйтесь</Link></p>
                    }
                    <FormErrors errors={errors} />
                    <form action="" onSubmit={(e) => sendRequest(e)} aria-disabled={formBusy} className="nice-form js-auth-form mt__normal">
                        <div className="cols cols-2 p10">
                            <div className="col">
                                <label className="input-wrap">
                                    <input
                                        type="password"
                                        name={'password'}
                                        placeholder=" "
                                        onInput={e => setPassword(e.target.value)}
                                        value={password}
                                        required={true}
                                    />
                                    <div className="input-wrap__label">
                                        Пароль
                                    </div>
                                </label>
                            </div>
                            <div className="col">
                                <label className="input-wrap">
                                    <input
                                        type="password"
                                        name={'passwordRepeat'}
                                        placeholder=" "
                                        onInput={e => setPasswordRepeat(e.target.value)}
                                        value={passwordRepeat}
                                        required={true}
                                    />
                                    <div className="input-wrap__label">
                                        Повтор пароля
                                    </div>
                                </label>
                            </div>
                            <div className="col">
                                <SmallButton addClass='mt__none'>Отправить</SmallButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
}
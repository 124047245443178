import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {analyticsBACK, APIClient} from "../../ApiEndpoints";
import {DOCUMENT_AND_STORIES_STAT} from "../../graphql/statisticQueries";
import Skeleton from "react-loading-skeleton";
import {useSearchParams} from "react-router-dom";
import SearchInput from "../../components/ui/SearchInput";
import {PLATFORM_PAGE} from "../../graphql/platformsQueries";
import {setPlatformsManifest} from "../../reducers/platformSlice";
import Tooltip from "../../components/ui/Tooltip";
// import {SavedPlatformSelect} from "../../components/platforms/SavedPlatformSelect";

const PlatformsCountriesFilter = React.lazy(() => import("../../components/platforms/PlatformsCountriesFilter"))
const PlatformTypeFilter = React.lazy(() => import("../../components/platforms/PlatformTypeFilter"))
const PlatformsPager = React.lazy(() => import("../../components/platforms/PlatformsPager"))
const PlatformsTable = React.lazy(() => import("../../components/platforms/PlatformsTable"))
const PlatformsSelection = React.lazy(() => import("../../components/platforms/PlatformsSelection"))
const PlatformsSaveButton = React.lazy(() => import("../../components/platforms/PlatformsSaveButton"))
const SavedPlatformSelect = React.lazy(() => import('../../components/platforms/SavedPlatformSelect'))

const PlatformsPage = ({asSelector = false, sendSelectedPlatforms = undefined, sendAsList = undefined}) => {
	const [countriesSelected, setCountriesSelected] = useState([])
	const [typesSelected, setTypesSelected] = useState('')
	const [mainStat, setMainStat] = useState({docs: '', sources: ''})
	const [fetchStat, setFetchStat] = useState(false)
	const [fetchDocs, setFetchDocs] = useState(false)
	const [query, setQuery] = useSearchParams()
	const platformTypes = useSelector((state) => state.platform.platforms)
	const platformManifest = useSelector((state) => state.platform.manifest)
	const [documentsList, setDocementsList] = useState([])
	const [selectedPlatforms, setSelectedPlatforms] = useState({})
	const [searchString, setSearchString] = useState('')
	const [platformsCount, setPlatformsCount] = useState(0)
	const [pageSize, setPageSize] = useState(20)
	const [currentPage, setCurrentPage] = useState((query.get('page'))? parseInt(query.get('page')) : 1)
	const dispatch = useDispatch()
	const [errors, setError] = useState({
		platforms: false
	})

	useEffect(() => {//парсим запрос при заходе
		const variables = {
			filterSettings: {},
			offset: (query.get('page')) ? (query.get('page') - 1) * pageSize : 0
		}

		if (query.get('countries')) {
			setCountriesSelected(query.get('countries').split('|'))
			if (query.get('countries').split('|').length > 0)
				variables.filterSettings.country = query.get('countries').split('|')
		}
		if (query.get('types')) {
			setTypesSelected(query.get('types'))
			variables.filterSettings.platformType = query.get('types')
		}
		if (query.get('page') > '1') {
			setCurrentPage(parseInt(query.get('page')))
		}
		if (query.get('q')) {
			setSearchString(query.get('q'))
			variables.filterSettings.searchString = query.get('q')
		}
		getPlatforms(variables);
	}, []);

	useEffect(() => {//query builder
		let qObj = {}
		if (countriesSelected.length > 0)
			qObj.countries = countriesSelected.join('|')
		if (typesSelected)
			qObj.types = typesSelected
		if (searchString)
			qObj.q = searchString
		if (currentPage > 1)
			qObj.page = currentPage


		setQuery(qObj)
	}, [typesSelected, countriesSelected, searchString, currentPage])


	useEffect(() => {
		setFetchStat(true)
		APIClient.query({
			query: DOCUMENT_AND_STORIES_STAT,
		})
			.then((res) => {
				if (res.data) {
					setMainStat({
						docs: res.data.paginationStory.showTotal,
						sources: res.data.paginationPlatform.total
					})
				}
			})
			.finally(() => {
				setFetchStat(false)
			})
	}, [])

	useEffect(() => {
		if(!asSelector)
			document.title = 'Мгимо аналитика: создание набора источников'
	}, [])

	useEffect(() => {//получаем манифест
		if(Object.keys(platformManifest).length === 0) {
			analyticsBACK.get('/platformsManifest')
				.then((resp) => {
					dispatch(setPlatformsManifest(resp.data))
				})
		}
	})

	const changePage = (number) => {
		if (number) {
			setCurrentPage(parseInt(number))
			searchPlatforms(parseInt(number))
		}
	}

	const handlePlatformSelect = (platforms) => {//меняем платформы тут и у родителя
		if(asSelector && typeof sendSelectedPlatforms === 'function')
			sendSelectedPlatforms(platforms)

		setSelectedPlatforms(platforms)
	}

	const getPlatforms = (variables) => {
		setFetchDocs(true)
		APIClient.query({
			query: PLATFORM_PAGE,
			variables: variables
		})
			.then((resp) => {
				if (resp.data && resp.data.paginationPlatform && resp.data.paginationPlatform.listPlatform)
					setDocementsList(resp.data.paginationPlatform.listPlatform)

				if (resp.data && resp.data.paginationPlatform && resp.data.paginationPlatform.total)
					setPlatformsCount(resp.data.paginationPlatform.total)

				setError({...errors, platforms: false})
				// console.log('DOCS_FETCH', resp)
			})
			.catch((resp) => {
				setError({...errors, platforms: true})
				setPlatformsCount(0)
				console.error('DOCS_FETCH_FAIL')
			})
			.finally(() => {
				setFetchDocs(false)
			})
	}

	const searchPlatforms = (page = 1, pSize = pageSize) => {
		const offset = (page === 0) ? (pageSize * (pSize - 1)) : (page - 1) * pSize
		const variables = {
			filterSettings: {},
			offset: offset,
			limit: pSize
		}
		if(page !== query.get('page'))
			setCurrentPage(parseInt(page))

		if (countriesSelected.length > 0)
			variables.filterSettings.country = countriesSelected
		if (typesSelected)
			variables.filterSettings.platformType = typesSelected
		if (searchString)
			variables.filterSettings.searchString = searchString

		getPlatforms(variables)
	}

	const changePageSize = (size) => {//изменение размера страницы
		setPageSize(parseInt(size))
		searchPlatforms(1, parseInt(size))
	}

	const setAllPlatforms = () => {//выбрать все возможные документы
		if(typeof  sendSelectedPlatforms === 'function')
			sendSelectedPlatforms("all")
	}

	const setPlatformsFromList = (list) => {
		if(typeof sendAsList === 'function')
			sendAsList(list)
	}



	return <>
		<div className={(!asSelector)? "main-content" : ''}>
			<div className={"content visible"}>
				<div className="flex_row main-margin flex_row--sp_between">
					<h1 className="title-big c-main-dark">
						{asSelector
							? <>Выбор источников:
								<Tooltip>
									В можете выбрать источник из списка сохраненных источников, либо указать список источников вручную.
									При нажатии кнопки <button className='btn small'>Выбрать все доступные источники</button>, поиск документов будет осуществлен по всем имеющимся в системе документам.
								</Tooltip>
								</>
							: <>Список источников</>
						}
					</h1>

					<div className="flex_row">
						<div className="text--small text--light" style={{marginRight: '2.3rem'}}>
							Общее количество источников<br/>
							СМИ, соц. сети, блоги и др.
						</div>

						<div className={"title--middle"}>
							{fetchStat
								? <Skeleton width='100px' height="40px"/>
								: mainStat.sources
							}
						</div>
					</div>
				</div>

				{asSelector &&
					<>
						<div className='flex_row flex_row--sp_between flex_row--to_top'>
							<SavedPlatformSelect setPlatformList={setPlatformsFromList} setAll={setAllPlatforms} />

						</div>
					</>
				}
			</div>
			<div className={'bg_l_gray platforms-search-block'}>
				<div className={'content visible'}>
					<div className="title c-main-dark">
						Поиск источника по названию:
					</div>
				<SearchInput
					disabled={fetchDocs}
					query={searchString}
					setQuery={setSearchString}
					placeholder={'Введите название источника'}
					respAction={searchPlatforms}
					submit={'Искать'}
					style={'input-group'}
				/>
				</div>
			</div>
			<div className={'content'}>

				<div className={'grid grid--even block filter-blocks'}>
					<PlatformsCountriesFilter selected={countriesSelected} setSelected={setCountriesSelected}/>
					<PlatformTypeFilter selected={typesSelected} setSelected={setTypesSelected}/>
				</div>

				<div className={'title-big c-main-dark'}>
					Источники:
				</div>

				{errors.platforms
					? <div className="error">Ошибка при получении списка источников</div>
					: <PlatformsTable
						total={platformsCount}
						selectedPlatforms={selectedPlatforms}
						selectPlatforms={handlePlatformSelect}
						fetching={fetchDocs}
						platforms={documentsList}
						pageSize={pageSize}
						setPageSize={changePageSize}
					/>
				}

				{!fetchDocs && !errors.platforms && platformsCount > pageSize &&
					<PlatformsPager total={platformsCount} pageSize={pageSize} setCurrent={changePage}
					                currentNumber={currentPage}/>
				}
				<div style={(platformsCount > 0)? {opacity: '1', pointerEvents: 'all'} : {opacity: '0', pointerEvents: 'none'}}>
					<PlatformsSelection fromStream={asSelector} platforms={selectedPlatforms} setPlatforms={handlePlatformSelect}/>
					{!asSelector &&
						<PlatformsSaveButton platforms={selectedPlatforms}/>
					}
				</div>
			</div>
		</div>
	</>
}

export default PlatformsPage
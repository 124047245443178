import React from "react"
import LoaderPage from "../../components/laders/LoaderPage";
import authLogo from "../../img/auth-logo.svg";
import authText from "../../img/auth-text.svg";

const Register = () => {


	return <div className="auth-page">
		<div className="content visible">
			<LoaderPage loading=""/>
			<div className="auth-page-content">
				<div className="auth-page-content__left">
					<img src={authLogo} alt="" className="auth-page-logo"/>
				</div>
				<div className="auth-page-content__right">
					<img src={authText} alt="" className="auth-page-text"/>

					<div className="title">Подтверждение регистрации</div>

					<p className="light auth-page-desc">Для активации вашей учетной записи, необходимо
						подтверждение администратором сайта. Пожалуйста, ожидайте письмо об активации учетной записи.</p>

				</div>
			</div>
		</div>
	</div>
}

export default Register
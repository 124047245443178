export const parseText = ({text, textMode = false, skipHeaders = false}) => {
	let resultHtml = ''
	let level = 0
	let mode = ''
	let innerText = ''

	if(text)
		text.forEach(textItem => {
			let currentLevel = textItem.hierarchyLevel
			switch (textItem.metadata.paragraphType){
				case 'key':{
					if(mode === 'list')
						resultHtml += `</ul>`
					if(mode === 'table')
						resultHtml += '</tbody></table>'

					level = currentLevel
					mode = textItem.metadata.paragraphType

					if (textItem.text && !skipHeaders)
						resultHtml += `<p><b>${textItem.text}</b></p>`

					break
				}
				case 'list': {
					if(mode === 'table')
						resultHtml += '</tbody></table>'
					if(mode === 'list')
						resultHtml += `</ul>`
					if(mode === 'row')
						resultHtml += `</tr></tbody></table>`
					if(mode === 'cell')
						resultHtml += `</td></tr></tbody></table>`

					level = currentLevel
					mode = textItem.metadata.paragraphType
					resultHtml += `<p><h6>${textItem.text}</h6></p><ul>`

					break
				}
				case 'header': {
					if(mode === 'list')
						resultHtml += `</ul>`
					if(mode === 'table')
						resultHtml += '</tbody></table>'

					level = currentLevel
					mode = textItem.metadata.paragraphType
					resultHtml += `<h6>${textItem.text}</h6>`

					break
				}
				case 'row':{
					if(mode === 'list')
						resultHtml += `</ul>`
					if(mode === 'row')
						resultHtml += `</tr>`
					if(mode === 'cell')
						resultHtml += `</td>`

					mode = textItem.metadata.paragraphType
					resultHtml += `<tr>`

					break
				}

				case 'cell': {
					if(mode === 'list')
						resultHtml += `</ul>`
					if(mode === 'cell')
						resultHtml += `</td>`
					if(mode === 'list')
						resultHtml += `</ul>`

					mode = textItem.metadata.paragraphType
					resultHtml += `<td>`

					break
				}

				case 'table': {
					if(mode === 'list')
						resultHtml += `</ul>`
					if(mode === 'table')
						resultHtml += '</tbody></table>'
					if(mode === 'row')
						resultHtml += '</tr></tbody></table>'
					if(mode === 'cell')
						resultHtml += `</td></tr></tbody></table>`

					level = currentLevel
					mode = textItem.metadata.paragraphType
					resultHtml += `<h6>${textItem.text}</h6><table><tbody>`

					break
				}

				case 'text':{
					if((mode === 'header' || mode === 'key') && currentLevel > level){
						resultHtml += `<div>${textItem.text}</div>`
					}else if(mode === 'list' && currentLevel > level){
						resultHtml += `<li>${textItem.text}</li>`
					}else{
						if (mode === 'cell')
							resultHtml += `${textItem.text}`
						else
							resultHtml += (textItem.text)? `<p>${textItem.text}</p>` : ''
					}


					innerText += textItem.text

					break
				}
			}
		})

	if(mode === 'list')
		resultHtml += `</ul>`
	if(mode === 'table')
		resultHtml += '</tbody></table>'
	if(mode === 'cell')
		resultHtml += '</td></tr></tbody></table>'
	if(mode === 'row')
		resultHtml += '</tr></tbody></table>'

	if(textMode)
		return  innerText
	else
		return {__html: resultHtml}
}
import React from "react"
import LoaderPage from "../../components/laders/LoaderPage";
import authLogo from "../../img/auth-logo.svg";
import authText from "../../img/auth-text.svg";
import {Link} from "react-router-dom";

export default function Success(){

	return <div className="auth-page">
		<div className="content visible">
			<LoaderPage loading=""/>
			<div className="auth-page-content">
				<div className="auth-page-content__left">
					<Link to={"/"}><img src={authLogo} alt="" className="auth-page-logo"/></Link>
				</div>
				<div className="auth-page-content__right">
					<Link to={"/"}><img src={authText} alt="" className="auth-page-text"/></Link>

					<div className="title">Регистрация завершена</div>

					<p className="light auth-page-desc">
						Ваша регистрация завершена, вы можете авторизоваться.
						Для этого перейдите <Link to="/"> на главную страницу</Link>.
					</p>

				</div>
			</div>
		</div>
	</div>
}
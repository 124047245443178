import React from "react";
import AuthForm from "../../components/auth/AuthForm";

import authLogo from '../../img/auth-logo.svg'
import authText from '../../ico/logo_autorization.svg'
import LoaderPage from "../../components/laders/LoaderPage";
import {useSearchParams} from "react-router-dom";
import {useSelector} from "react-redux";

const AuthPage = () => {
	const [search, setSearch] = useSearchParams();
	const appVersion = useSelector((state) => state.app.appVersion)
	const backVersion = useSelector((state) => state.app.backVersion)

	return <div className="auth-page">
		<div className="content visible">
			<LoaderPage loading="" />
			<div className="auth-page-content">
				<div className="auth-page-content__left">
					<img src={authLogo} alt="" className="auth-page-logo"/>
				</div>
				<div className="auth-page-content__right">
					<img src={authText} alt="" className="auth-page-text"/>

					{search.get('register') &&
						<div className="title">Вы успешно зарегистрировались, используйте введенный логин и пароль.</div>
					}

					<div className="title">Система анализа данных представляет собой самостоятельную систему для
						моделирования и построения социальных связей исследуемых объектов – конкретных публичных
						акторов
						международных отношений (государств, персон или организаций).
					</div>

					<p className="small light auth-page-desc">Цель системы – обеспечение удобной работы
						пользователей в части выявления
						явных или скрытых социальных связей объектов исследования – участников международных
						отношений. Для авторизации необходимо использовать логин и пароль от сервиса Талисман. </p>

					<AuthForm />
				</div>
			</div>
		</div>

		<div className="auth_footer">Версия фронтенда: {appVersion}, версия бэкенда: {backVersion}</div>
	</div>
}

export default AuthPage
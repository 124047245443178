import React, {useEffect, useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {Link, useSearchParams} from "react-router-dom";
import Tooltip from "../../components/ui/Tooltip";
import {APIClient} from "../../ApiEndpoints";
import {DOCUMENT_AND_STORIES_STAT} from "../../graphql/statisticQueries";
import {Calendar, utils} from "@hassanmojab/react-modern-calendar-datepicker";
import {CALENDAR_LOCALE} from "../../Consts";
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

const QueryStringConstructor = React.lazy(() => import("../../components/stream/QueryStringConstructor"))
const PlatformsPage = React.lazy(() => import("../Platforms/PlatformsPage"))
const PlatformFromSaved = React.lazy(() => import( "../../components/platforms/PlatformFromSaved"))

const ShowBtn = ({phrases, sources, concepts, conceptLogic, selectedPeriod = false, selectedDay = false, is_static = false}) => {
	if ((concepts.length > 0 || phrases.length > 0)) {
		const parseDate = (date) => {
			return ('0'+date.day).slice(-2) + '.' + ('0'+date.month).slice(-2) + '.' + date.year
		}
		let URL = []

		if(sources.length === 0){
			URL.push('sources=all')
		} else {
			URL.push('sources=' + sources.join(','))
		}

		if(selectedDay){
			console.log("selectedDay", selectedDay)

			if(selectedDay.day && selectedDay.month && selectedDay.year) {
				URL.push(`dateFrom=${parseDate(selectedDay)}`)
			}
		}
		if(selectedPeriod && selectedPeriod.from !== null && selectedPeriod.to !== null){
			URL.push(`dateFrom=${parseDate(selectedPeriod.from)}&dateTo=${parseDate(selectedPeriod.to)}`)
		}

		let qArray = [...phrases]
		if(phrases.length > 0 && concepts.length > 0)
			qArray = [...qArray, conceptLogic, ...concepts]
		else if(concepts.length > 0)
			qArray = [...concepts]

		URL.push('q='+JSON.stringify(qArray))

		if(is_static)
			return <Link to={`/stream/static/content?${URL.join('&')}`} className="btn stream-finish-btn">Сформировать поиск</Link>

		return <Link to={`/stream/content?${URL.join('&')}`} className="btn stream-finish-btn">Сформировать ленту</Link>
	}

	return <></>;
}

const PageStream = ({is_static = false}) => {// если is_static - то это поиск
	const user = useSelector(state => state.user.value)
	const [phrases, setPhrases] = useState([])
	// const savedConcepts = useSelector(state => state.user.savedConcepts)
	// const [search, setSearch] = useSearchParams();
	const [conceptSelect, setConceptSelect] = useState([]);
	const [conceptsLogic, setConceptLogic] = useState({text: 'В контексте концептов:', type: 'bool'})
	const [platforms, setPlatforms] = useState([])
	const [platformsSearchState, setPlatformsSearchState] = useState('init')
	const [selectedPlatformList, setSelectedPlatformList] = useState({})
	const [fetchStat, setFetchStat] = useState(false)
	const [mainStat, setMainStat] = useState({docs: '', sources: ''})
	const [selectedDay, setSelectedDay] = useState( null )
	const calendarRef = useRef();
	const calendarWrapperRef = useRef();
	const wrapper = useRef()
	const sticky = useRef()
	const [selectedDayRange, setSelectedDayRange] = useState({
		from: null,
		to: null
	});


	useEffect(() => {
		if(is_static)
			document.title = 'Мгимо аналитика: поиск документов'
		else
			document.title = 'Мгимо аналитика: лента документов'
	}, [])

	useEffect(() => {
		// Клик вне, для того чтобы скрыть календарь
		const event = (e) => {
			if(e.target.closest('.calendar_wrapper') !== calendarWrapperRef.current){
				calendarRef.current.classList.remove('active')
			}
		}
		document.addEventListener("click", event);
		return () => {
			document.removeEventListener("click", event)
		}
	}, [])


	useEffect(() => {// получаем статистику
		setFetchStat(true)
		APIClient.query({
			query: DOCUMENT_AND_STORIES_STAT,
		})
			.then((res) => {
				if (res.data) {
					setMainStat({
						docs: res.data.paginationStory.showTotal,
						sources: res.data.paginationPlatform.total
					})
				}
			})
			.finally(() => {
				setFetchStat(false)
			})
	}, [])

	const setAllPlatforms = () => {
		setPlatformsSearchState('all')
	}

	const setPlatformsFromObject = (obj) => {
		if(obj === 'all')
			setAllPlatforms()
		else if(typeof obj === 'object'){
			if(Object.keys(obj).length > 0)
				setPlatformsSearchState('selected')
			setPlatforms(Object.keys(obj))
		}
	}

	const setPlatformsFromSaved = ({list, platforms}) => {
		setSelectedPlatformList({name: list, platforms: platforms.split('|')})
		setPlatforms(platforms.split('|'))
		setPlatformsSearchState('fromSaved')
	}

	const removePlatformSelection = () => {
		setSelectedPlatformList({})
		setPlatformsSearchState('init')
	}

	const removeConcept = (index) => {
		setConceptSelect(conceptSelect.filter((itm, _index) => index !== _index))
	}

	const removePhrase = (index) => {
		if(index === 0 && phrases.length === 1){
			wrapper.current.classList.add('remove')
			sticky.current.classList.add('remove')
			setTimeout(() => {setPhrases([])},1000)
		}
		if(index > 0){
			setPhrases(phrases.filter((ph, i) => (index !== i && index-1 !== i)))
		}else if(index === 0 && phrases.length > 1){
			setPhrases(phrases.filter((ph, i) => (index !== i && index+1 !== i)))
		}
	}

	const changeLogic = (index) => {
		setPhrases(phrases.map((ph, i) => {
			if(i === index){
				if(ph.text === 'AND')
					ph.text = 'OR'
				else if(ph.text === 'OR')
					ph.text = 'NOT'
				else if(ph.text === 'NOT')
					ph.text = 'AND'
			}
			return ph
		}))
	}

	const printDate = (date) => {
		if(date && date.day && date.month && date.year)
			return ('0'+date.day).slice(-2) + '.' + ('0'+date.month).slice(-2) + '.' + date.year

		return ''
	}


	return <>
		{(phrases.length > 0 || conceptSelect.length > 0) &&
			<>
				<div ref={wrapper} className={'search-phrases-wrapper'}>
					<div className="content">
						<div className='title-big c-main-dark'>
							Ваш поисковый запрос:
							<Tooltip>
								Это сформированный поисковый запрос. <br />
								<span className='search-phrases__item search-phrases__item--text text--modal' style={{height: "auto"}}>В голубой</span> рамке указаны
								полнотекстовые поисковые фразы.<br />
								В случае, если поисковых фраз несколько, между ними можно выстраивать логические отношения. Возможны варианты AND, NOT, OR.
							</Tooltip>
						</div>
						<p>Вы можете менять логический оператор в поисковом запросе нажав на него. Возможны варианты AND, NOT, OR.</p>
					</div>
				</div>

				<div ref={sticky} className="search-phrases-sticky">
					<div className={'content'}>
						<div className="phrases">
							<div className='search-phrases'>
								{phrases.map((phrase, index) => {
									if (phrase.type === 'bool') {
										return <div key={`${phrase.text}_${index}`}
										            onClick={e => changeLogic(index)}
										            className={`search-phrases__item search-phrases__item--bool ${phrase.text}`}>{phrase.text}</div>
									}
									return <div key={`${phrase.text}_${index}`}
									            className='search-phrases__item title search-phrases__item--text'>{phrase.text}
										<div onClick={e => {
											removePhrase(index)
										}} className='cross'/>
									</div>
								})}

								{phrases.length > 0 && conceptSelect.length > 0 &&
									<div className={`search-phrases__item search-phrases__item--bool `}>В контексте концептов:</div>
								}

								{conceptSelect.map((concept, index) => {
									return <div key={concept.id}
									            className='search-phrases__item title search-phrases__item--concept'>{concept.text}
										<div onClick={e => {
											removeConcept(index)
										}} className='cross'/>
									</div>
								})}
							</div>
							{/*если внутренний пользователь и заполнены нужные поля или если внешний и заполнен текстовый поиск*/}
							{((user.group.code !== 'outer'  && (phrases || conceptSelect)) || (user.group.code === 'outer' && phrases)) &&
								<ShowBtn
									conceptLogic={conceptsLogic}
									concepts={conceptSelect}
									phrases={phrases}
									sources={(platformsSearchState !== 'init' && platforms.length > 0)? platforms : []}
									selectedDay={selectedDay}
									is_static = {is_static}
								/>
							}
						</div>
					</div>
				</div>
			</>
		}
		<main className='content visible' style={{marginTop: '57px'}}>
			<QueryStringConstructor phrases={phrases}
			                        setPhrases={setPhrases}
			                        searchSelect={conceptSelect}
			                        setSearchSelect={setConceptSelect}/>

			<hr/>
			{/*выбор платформы.*/}
		</main>
			<div>
				{(platformsSearchState === 'init' || platformsSearchState === 'selected') &&
					<PlatformsPage asSelector={true} sendSelectedPlatforms={setPlatformsFromObject} sendAsList={setPlatformsFromSaved} />
				}
				{platformsSearchState === 'fromSaved' &&
					<div className={'content'}>
						<PlatformFromSaved platforms={selectedPlatformList.platforms} name={selectedPlatformList.name} crossClick={removePlatformSelection} />
					</div>
				}
			</div>
		<main className={'content'}>
			{/*дата начала отбора публикаций*/}
			<hr style={{margin: "3rem 0"}}/>
			{is_static
				? <div>
					<div ref={calendarWrapperRef} className='flex_row flex_row--to_left fle calender_parent' style={{gap: "2.5rem", position: 'relative'}}>
						<div ref={calendarRef} className="hidden_calendar" style={{left: "50%", transform: 'translatex(-50%)'}}>
							<Calendar
								value={selectedDayRange}
								onChange={setSelectedDayRange}
								shouldHighlightWeekends
								maximumDate={utils().getToday()}
								locale={CALENDAR_LOCALE}
								colorPrimary="#5038EC" // added this
								colorPrimaryLight="#EFEFFF" // and this
							/>
						</div>
						{selectedDayRange.from !== null && selectedDayRange.to !== null
							? <>
								<div className='text--as_title text--dark_blue'>Публикации за период: </div>
								<div className='text--as_title text--dark_blue'>
									{printDate(selectedDayRange.from) + ' - ' + printDate(selectedDayRange.to)}
								</div>
								<div className="calendar_wrapper text--blue" >
									<span onClick={() => {calendarRef.current.classList.toggle('active')}}>Изменить период</span>
								</div>
							</>
							: <>
								<div className='calendar_wrapper' style={{position: "relative"}}>
									<button className='btn small' onClick={() => {calendarRef.current.classList.toggle('active')}}>
										Установить период отбора публикаций
									</button>
								</div>
								<p className='text--gray'>Если период не установлен поиск будет осуществляться из всех возможных публикаций. </p>
							</>
						}
					</div>
				</div>
				: <div>
					<div className='flex_row flex_row--to_left fle' style={{gap: "2.5rem"}}>
						{selectedDay
							? <>
								<div className='text--as_title text--dark_blue'>Публикации начиная с: </div>
								<div className='text--as_title text--dark_blue'>{('0'+selectedDay.day).slice(-2) + '.' + ('0'+selectedDay.month).slice(-2) + '.' + selectedDay.year}</div>
								<div className="calendar_wrapper text--blue" ref={calendarWrapperRef}>
									<span onClick={() => {calendarRef.current.classList.toggle('active')}}>Изменить период</span>
									<div ref={calendarRef} className="hidden_calendar">
										<Calendar
											value={selectedDay}
											onChange={setSelectedDay}
											shouldHighlightWeekends
											maximumDate={utils().getToday()}
											locale={CALENDAR_LOCALE}
											colorPrimary="#5038EC" // added this
											colorPrimaryLight="#EFEFFF" // and this
										/>
									</div>
								</div>
							</>
							: <>
								<div ref={calendarWrapperRef} className='calendar_wrapper' style={{position: "relative"}}>
									<button className='btn small' onClick={() => {calendarRef.current.classList.toggle('active')}}>
										Установить дату начала отбора публикаций
									</button>
									<div ref={calendarRef} className="hidden_calendar">
										<Calendar
											value={selectedDay}
											onChange={setSelectedDay}
											shouldHighlightWeekends
											maximumDate={utils().getToday()}
											locale={CALENDAR_LOCALE}
											colorPrimary="#5038EC" // added this
											colorPrimaryLight="#EFEFFF" // and this
										/>
									</div>
								</div>

								<p className='text--gray'>Если период не установлен поиск будет осуществляться из всех возможных публикаций. </p>
							</>
						}
					</div>
				</div>
			}


			<hr style={{margin: "3rem 0"}}/>
		</main>
	</>
}
export default PageStream;
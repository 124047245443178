export const uniDeclension = (num, str) => {
	num = ((num < 0) ? num-num*2 : num)%100;
	let dig = (num > 20) ? num%10 : num;
	return  ((dig == 1) ? str[0] : ( (dig > 4 || dig < 1) ? str[1] : str[2]) );
}

export const parseTQLQuery = (searchQuery) =>{
	let tmp = ''
	let concepts = []
	let tmpName = ''
	searchQuery.forEach((phrase, index) => {//обходим фразы
		if(phrase && phrase.type === 'text'){//если это текстовая фраза просто записываем ее в serchString
			tmp += phrase.text
			tmpName += ' ' + phrase.text
		}
		if(phrase && phrase.type === 'concept'){//если это концепт
			concepts.push(phrase.id)
			if(searchQuery[index-1].type === 'concept'){//если предыдущая фраза это концепт, то надо поставить запятую
				tmpName += ','
			}
			tmpName += ' ' + phrase.text
		}
		if(phrase && phrase.type === 'bool'){//если это логический оператор
			switch (phrase.text){
				case 'AND': {
					tmp += ','
					tmpName += ' и '
					break
				}
				case 'NOT': {
					tmp += '-'
					tmpName += ' не '
					break
				}
				case 'В контексте концептов:': {
					tmpName += ' В контексте концептов: '
					break
				}
				default: {//or
					tmp += ' '
					tmpName += ' или '
					break
				}
			}
		}
	})
	return [tmp, concepts, tmpName]
}
import React from "react";

const FormErrors = ({errors = []}) => {
	const printErrors = () => {
		return errors.map((error, index) => <p key={index} className='formError'>{error}</p>)
	}

	return <div className="formErrors">
		{printErrors()}
	</div>
}

export default FormErrors